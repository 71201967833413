import React from "react";
import "react-responsive-modal/styles.css";
import {
  CogIcon,
  HomeIcon,
  UserGroupIcon,
  BellIcon,
  SpeakerphoneIcon,
} from "@heroicons/react/outline";
import { BuildingIcon } from "src/assets/icons";

const PublicMenu = props => {
  const { title, onMenuClick } = props;

  let menuIcon;

  switch (title) {
    case "Data":
      menuIcon = <HomeIcon />;
      break;
    case "Contacts":
      menuIcon = <UserGroupIcon />;
      break;
    case "Discover":
      menuIcon = <BuildingIcon />;
      break;
    case "Posts":
      menuIcon = <SpeakerphoneIcon />;
      break;
    case "Office":
      menuIcon = <CogIcon />;
      break;
    case "Notifications":
      menuIcon = <BellIcon />;
      break;
  }

  return (
    <div
      className="px-2 cursor-pointer flex items-center space-x-1 hover:underline"
      onClick={onMenuClick}
    >
      <div className="h-4 w-4">{menuIcon}</div>
      <span>{title}</span>
    </div>
  );
};

export default PublicMenu;
