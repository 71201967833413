import React, { useState } from "react";
import Navbar from "./navbar";
import TopBar from "./topbar";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/LoadingScreen";
import "./navbar.css";
import "src/assets/elements.css";
import { pageTitleSelector, sectionLinksSelector } from "src/slices/global";
import NotificationPanel from "./notification";
import useAuth from "src/hooks/useAuth";

const DashboardLayout = ({ children }) => {
  const { loading, platformLoading } = useSelector(state => state.app);
  const pageTitle = useSelector(pageTitleSelector);
  const sectionLinks = useSelector(sectionLinksSelector);

  const [showNotification, setShowNotification] = useState(false);
  const [notiUnreadNum, setNotiUnreadNum] = useState(0);
  const { user } = useAuth();
  const [postObj, setPostObj] = useState({});

  // if (platformLoading) {
  //   return <LoadingScreen />;
  // }

  if (!user) {
    return (
      <div>
        <TopBar postObj={postObj} />
        <div className="dashboard bg-white p-8">{children}</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="ml-40 flex flex-col items-start h-full">
        <div className="">
          <TopBar
            onBellClicked={() => setShowNotification(true)}
            notiUnreadNum={notiUnreadNum}
            postObj={postObj}
          />
        </div>
        <div className="dashboard bg-white p-8">
          <div className="flex items-baseline space-x-6">
            {pageTitle && <h1 className="h1 mt-12 mb-4">{pageTitle}</h1>}
            {sectionLinks && (
              <>
                <div className="flex items-baseline space-x-2">
                  {sectionLinks.map(sectionLink => (
                    <a
                      className="uppercase text-sm"
                      key={sectionLink.name}
                      href={sectionLink.link}
                    >
                      {sectionLink.name}
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="main-content mt-4 mb-8 bg-white rounded-sm">
            {loading ? <LoadingScreen /> : <>{children}</>}
          </div>
        </div>
        <NotificationPanel
          isShow={showNotification}
          onClose={val => setShowNotification(val)}
          onRefresh={val => setNotiUnreadNum(val)}
          onToPost={val => setPostObj(val)}
        />
      </div>
    </div>
  );
};

export default DashboardLayout;
